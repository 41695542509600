import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/SEO';
import { FiArrowRight } from 'react-icons/fi';
import './about.scss';
import homeImg from '../images/home-bg.svg';

const AboutPage = ({ data }) => (
  <Layout>
    <SEO title="About" />
    <div className="about-middle">
      <div className="about-context">
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{
            __html: data.profile.aboutMe.childMarkdownRemark.html,
          }}
        ></div>
        <Link to="/portfolio" className="about-link link">
          MY PORTFOLIO <FiArrowRight />
        </Link>
      </div>
      <div className="about-middle-img">
        <img src={homeImg} className="about-img" alt="About Image" />
      </div>
    </div>
  </Layout>
);

export default AboutPage;
export const query = graphql`
  {
    profile: contentfulAuthor {
      name
      githubHandle
      instagramHandle
      linkedinHandle
      biography {
        biography
      }
      aboutMe {
        childMarkdownRemark {
          html
        }
        aboutMe
      }
      profilePhoto {
        file {
          url
        }
      }
    }
  }
`;
